#adjective-container {
  position: relative;
}

#adjective-container.adjective-sm {
  top: -3rem;
  animation: fade-in-top-fade-out-bottom 3s ease-in-out infinite;
}


#adjective-container.adjective-xs {
  left: -3rem;
  animation: fade-in-left-fade-out-right 3s ease-in-out infinite;
}

@keyframes fade-in-top-fade-out-bottom {
  0% {
    opacity: 0;
    top: -3rem; 
  }

  25% { 
    opacity: 1;
    top: 0px; 
  }

  75% { 
    opacity: 1;
    top: 0px;
  }
  
  100% {
    opacity: 0;
    top: 3rem; 
  }
}

@keyframes fade-in-left-fade-out-right {
  0% {
    opacity: 0;
    left: -3rem; 
  }

  20% { 
    opacity: 1;
    left: 0px; 
  }

  80% { 
    opacity: 1;
    left: 0px;
  }
  
  100% {
    opacity: 0;
    left: 3rem; 
  }
}